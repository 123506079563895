window.module 'Booksession', ->
  @module 'External', ->
    @module 'Galleries', ->
      @module 'Show', ->
        @init = ->
          initCover()
          initJaralax()
          initMosaic()
          initFilters()
          initScrollToGallery()
          initToggleFavorite()


        initScrollToGallery =->
          $('a#open_collection').click (e) ->
            e.preventDefault()
            scrollToAnchor 'collection'

        initCover =->
          collectionCoverEl = $('#gallery-cover')
          previewWarningOffset = 0
          if $('#preview-warning').length > 0
            previewWarningOffset = $('#preview-warning').height()
          if collectionCoverEl.length > 0
            if $('#nav-wrapper-mobile').is(':visible')
              collectionCoverEl.css 'height', $(window).height() - previewWarningOffset
            else
              collectionCoverEl.css 'height', $(window).height() - previewWarningOffset
              $(window).resize ->
                collectionCoverEl.css 'height', $(window).height() - previewWarningOffset

        initJaralax =->
          jarallax(document.querySelectorAll('.jarallax'))

        initFilters =->
          $('#gallery_filters').find('a.album').on 'click', (e) ->
            loadAlbum $(@)

          $('#gallery_filters').find('select').on 'change', (e) ->
            loadAlbum $(@).find('option:selected')

        loadAlbum = (elem) ->
          url = elem.data('url')

          $('#gallery_filters').find('a.album').removeClass('border-green-500 selected')
          $('#gallery_filters').find("a.album[data-url='#{url}']").addClass('border-green-500 selected')

          $('#gallery_filters').find('select').find("option[data-url='#{url}']").prop('selected', true)

          $.get url, (data) ->
            $('#js-grid-inline3').replaceWith data
            setTimeout (->
              initMosaic()
            ), 500

        scrollToAnchor = (aid) ->
          aTag = $("##{aid}")
          $('html,body').animate { scrollTop: aTag.offset().top }, 'slow'

        initToggleFavorite =->
          $('body').on 'click', 'a.toggle-favorite', (e) ->
            e.preventDefault()
            self = $(@)

            $.ajax
              url: self.data('url')
              method: 'PATCH'
              success: (data) ->
                $('a.favorite span span').text "(#{data.favorite_count})"
                self.find('svg').toggleClass('text-red-400')


        initMosaic =->
          display_type = 'default'
          caption = 'overlayBottomAlong'

          $('#js-grid-inline3').cubeportfolio
            filters: '#js-inline3-filter'
            layoutMode: 'grid'
            lightboxGallery: false
            mediaQueries: [
              {
                width: 1500
                cols: 4
              }
              {
                width: 1100
                cols: 3
              }
              {
                width: 800
                cols: 2
              }
              {
                width: 670
                cols: 2
              }
              {
                width: 320
                cols: 1
              }
            ]
            defaultFilter: '*'
            animationType: 'quicksand'
            gapHorizontal: 10
            gapVertical: 10
            gridAdjustment: 'responsive'
            caption: caption
            displayType: display_type
            displayTypeSpeed: 100,

            lightboxDelegate: '.cbp-lightbox',
            lightboxGallery: true,
            lightboxTitleSrc: 'data-title',
            lightboxCounter: '<div class="cbp-popup-lightbox-counter">{{current}} of {{total}}</div>',

            singlePageDelegate: null
            # plugins:
              # loadMore:
                # element: '#js-loadMore-masonry-projects'
                # action: 'auto'
                # loadItems: 8



$(document).on "turbo:load", ->
  Booksession.External.Galleries.Show.init() if $('#galleries-show.external-gallery').length
